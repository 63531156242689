import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { IFavourites, IUserData } from "../models/models";
import { Box } from "@mui/system";
import { Button, Card, CardMedia, Grid, Stack, Tooltip } from "@mui/material";
import { checkIfUserIsLogged, handleFavouritesApi } from "../api/getData";
import Loading from "../components/Loading";
import { SentimentNeutral } from "@mui/icons-material";
import VideoCard from "../components/VideoCard";
import { Link } from "react-router-dom";

interface IFavouritesProps {
  userData: IUserData;
  setUserData: (userData: IUserData) => void;
}

const Favourites: React.FC<IFavouritesProps> = ({ userData, setUserData }) => {
  // Check if userData and isLogged are valid
  const isLoggedIn = userData?.isLogged;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkIfLogged = async () => {
    try {
      setIsLoading(true);
      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    checkIfLogged();
  }, []);

  if (isLoading) return <Loading />;

  if (!isLoggedIn && !isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="54vh"
        alignContent="center"
      >
        <SentimentNeutral sx={{ fontSize: 56, color: "orange" }} />
        <Typography
          variant="h4"
          color="textSecondary"
          gutterBottom
          textAlign="center"
        >
          Please log in to watch your favorites docs.
        </Typography>
      </Box>
    );
  }
  if (userData?.favourites?.length === 0)
    return <h3 style={{ justifySelf: "center" }}>You have no favorites</h3>;

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "6px",
        backgroundColor: "transparent",
      }}
    >
      <Typography
        variant="h4" // Header size
        sx={{
          marginBottom: "20px", // Space below the header
          color: "black",
          justifySelf: "center",
        }}
      >
        {" "}
        Your favorites
      </Typography>
      <Grid
        container
        spacing={2} // Spacing between Grid items
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {userData.favourites.map((doc, index) => (
          <Grid
            item
            key={doc._id}
            xs={12} // 1 card per row on extra small screens
            sm={6} // 2 cards per row on small screens
            md={3} // 4 cards per row on medium screens and up
          >
            <FavVideoCard
              favInfo={doc}
              userData={userData}
              setUserData={setUserData}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const FavVideoCard = ({ favInfo, userData, setUserData }: any) => {
  let singleEndpointType =
    favInfo?.type === "docs" ? "singleDoc" : "singleTalk";

  const handleRemove = async (favInfo: IFavourites) => {
    try {
      const action = "delete";
      const response = await handleFavouritesApi(
        userData?.email,
        action,
        favInfo
      );
      if (response)
        setUserData({ ...userData, favourites: response?.favourites });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Link
      to={`/${singleEndpointType}/${favInfo?._id}`}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          maxWidth: 384,
          height: 226,
          backgroundColor: "#F8F8F8",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          boxShadow: 2,
          borderRadius: "12px", // Rounded corners for a modern look
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)", // Slight zoom effect on hover
            boxShadow: 4,
          },
        }}
        className="video-card"
      >
        {/* Card Media */}
        <CardMedia
          component="img"
          alt={favInfo?.title}
          height="172"
          image={favInfo?.image}
          sx={{
            borderTopLeftRadius: "12px", // Rounded corners at the top
            borderTopRightRadius: "12px",
            objectFit: "cover",
          }}
        />
        <Button
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundColor: "white",
            color: "black",
            padding: "5px 10px",
            borderRadius: "5px",
            fontSize: "12px",
            fontWeight: "bold",
            opacity: 0.7,
            border: "2px solid #fff",
            boxShadow: "0 0 10px rgba(255, 255, 255, 0.6)",
            animation: "glare 1.5s infinite",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleRemove(favInfo);
          }}
        >
          Remove
        </Button>
        {/* Card Content */}
        <Box
          sx={{
            p: 2, // Padding inside the card
            flexGrow: 1, // Ensures content spacing
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#FFF",
          }}
        >
          <Tooltip
            title={favInfo?.title}
            arrow
            placement="top"
            key={favInfo?.title.toString() + favInfo?.title.toString()}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#333", // Darker text for readability
                fontSize: "1.0rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis", // Ensure text truncates neatly
              }}
              gutterBottom
              component="div"
            >
              {favInfo?.title}
            </Typography>
          </Tooltip>
        </Box>
      </Card>
    </Link>
  );
};

export default Favourites;
