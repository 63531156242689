import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import AddModal from "./AddModal";
import { videoPlayApi } from "../api/getData";

let subtitleFile: string = ""; // Initialize as null

export interface IAppVideoPlayer {
  docUrl: string;
  previewImg: string;
  subs: boolean | undefined;
}

const AppVideoPlayer: React.FC<IAppVideoPlayer> = ({
  docUrl,
  previewImg,
  subs,
}) => {
  const [showSubtitles, setShowSubtitles] = useState(false);
  const [showButtons, setShowButtons] = useState(true); // Controls visibility of both buttons
  const [key, setKey] = useState(0); // Force re-render
  const [isVisible, setIsVisible] = useState(true);

  const { id } = useParams<{ id: string }>();
  const currentUrl = window.location.href;
  const mediaQuery = window.matchMedia("(max-width: 768px)");

  if (subs) {
    try {
      subtitleFile = currentUrl.includes("singleTalk")
        ? require(`./subs/talk${id?.toString()}.vtt`)
        : require(`./subs/${id?.toString()}.vtt`);
    } catch (error) {
      console.log("Subtitle file not found:", error); // Warn in the console if the file is missing
      subtitleFile = "";
    }
  } else {
    subtitleFile = "";
  }

  const onClose = () => {
    setIsVisible(false);
  };

  const handleVideoPlay = () => {
    videoPlayApi(id);
  };

  const hideButtons = () => {
    setShowButtons(false); // Hide both buttons
  };

  return (
    <div
      style={{
        marginTop: "20px",
        borderRadius: "12px",
        overflow: "hidden",
        width: mediaQuery?.matches ? "98%" : "80%",
        maxWidth: "1000px",
        height: "auto",
        aspectRatio: "16 / 9",
        position: "relative",
        boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
        backgroundColor: "#000",
        margin: "0 auto",
      }}
    >
      <ReactPlayer
        id="ad-container"
        key={key} // Force re-render
        controls
        url={docUrl}
        light={previewImg}
        playing={true}
        onPlay={handleVideoPlay}
        width="100%"
        height="100%"
        style={{
          borderRadius: "12px",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
            tracks: subtitleFile
              ? [
                  {
                    label: "Spanish",
                    kind: "subtitles",
                    src: subtitleFile,
                    srcLang: "en",
                    default: false,
                  },
                  {
                    label: "Spanish 2",
                    kind: "subtitles",
                    src: subtitleFile,
                    srcLang: "en",
                    default: false,
                  },
                ]
              : [],
          },
        }}
      />
      {showButtons && subtitleFile && (
        <>
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "45px",
              padding: "10px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Slight opacity
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              height: "40px", // Set height
              display: "flex",
              alignItems: "center", // Vertically center text
            }}
          >
            {"Subtítulos en español disponibles"}
          </button>
          <button
            onClick={hideButtons}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px", // Align next to the toggle button
              padding: "10px",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Same background as the toggle button
              borderRadius: "4px", // Square background
              border: "none",
              cursor: "pointer",
              height: "40px", // Same height as the toggle button
              display: "flex",
              alignItems: "center", // Vertically center text
              fontSize: "16px",
              color: "#000", // Black text
            }}
          >
            X
          </button>
        </>
      )}
      <AddModal isVisible={isVisible} onClose={onClose} />
    </div>
  );
};

export default AppVideoPlayer;
