export const editorsItems = [
  {
    id: 1,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/S7GbipYzQ767/ohBNEs9Q1cL3_320x180.jpg",
    title: "Missing 411",
    navigationId: "206",
  },
  {
    id: 2,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/ryiXXHTH9Ac9_640x360.jpg",
    title: "Monopoly",
    navigationId: "138",
  },
  {
    id: 3,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/RAp8Z8SU5IAA_320x180.jpg",
    title: "Diddy",
    navigationId: "418",
  },
  {
    id: 4,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/s3YiA3TtotSQ_320x180.jpg",
    title: "The Primacy of Consciousness",
    navigationId: "56",
  },
  {
    id: 5,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/0CWWCRAN9Vm4_320x180.jpg",
    title: "Samadhi 3",
    navigationId: "24",
  },
  {
    id: 6,
    imageUrl:
      "https://static-3.bitchute.com/live/cover_images/dancpAAhIini/JI5xlm4NEkBM_320x180.jpg",
    title: "Everything Is a Rich Man’s Trick",
    navigationId: "14",
  },
];

export const top20Array = [
  204, 205, 409, 330, 406, 151, 396, 405, 331, 361, 403, 343, 267, 399, 402,
  351, 373, 352, 1, 357,
];
