import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage";
import SingleDoc from "../pages/SingleDoc";
import ErrorPage from "../pages/ErrorPage";
import { IDoc, IUserData } from "../models/models";
import DrawerAppBar from "../components/AppBar";
import TagSearchResults from "../pages/TagSearchResults.";
import UserSubscribe from "../pages/UserSubscribe";
import Top20 from "../pages/Top20";
import Favourites from "../pages/Favourites";

export interface INavigator {
  docList: IDoc[];
  setDocList: (docList: IDoc[]) => void;
}

const Navigator: React.FC<INavigator> = ({ docList, setDocList }) => {
  const [numberOfDocs, setNumberOfDocs] = useState<number>(0);
  const [typeOfDocs, setTypeOfDocs] = useState<string>("docs");
  const [page, setPage] = useState<number>(1); //for pagination
  const [userData, setUserData] = useState<IUserData>({
    isLogged: false,
    email: "",
    favourites: [],
  });

  console.log(userData);

  return (
    <BrowserRouter>
      <DrawerAppBar
        numberOfDocs={numberOfDocs}
        typeOfDocs={typeOfDocs}
        setTypeOfDocs={setTypeOfDocs}
        setPage={setPage}
        userData={userData}
        setUserData={setUserData}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Homepage
              docList={docList}
              setDocList={setDocList}
              setNumberOfDocs={setNumberOfDocs}
              typeOfDocs={typeOfDocs}
              page={page}
              setPage={setPage}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/singleDoc/:id"
          element={
            <SingleDoc
              setNumberOfDocs={setNumberOfDocs}
              docList={docList}
              typeOfDocs={typeOfDocs}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/singleTalk/:id"
          element={
            <SingleDoc
              setNumberOfDocs={setNumberOfDocs}
              docList={docList}
              typeOfDocs={typeOfDocs}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/top20"
          element={
            <Top20
              allDocs={docList}
              setTypeOfDocs={setTypeOfDocs}
              setDocList={setDocList}
              setUserData={setUserData}
            />
          }
        />
        <Route
          path="/favourites"
          element={<Favourites userData={userData} setUserData={setUserData} />}
        />
        <Route
          path="/tag/:tag"
          element={
            <TagSearchResults
              allDocs={docList}
              setNumberOfDocs={setNumberOfDocs}
            />
          }
        />
        <Route
          path="/subscribe"
          element={
            <UserSubscribe userData={userData} setUserData={setUserData} />
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigator;
