import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo web.png";
import AppBarSelect from "./AppBarSelect";
import { IUserData } from "../models/models";
import ProfileCircle from "./ProfileCircle";

interface Props {
  window?: () => Window;
  numberOfDocs: number;
  typeOfDocs: string;
  setTypeOfDocs: (typeOfDoc: string) => void;
  setPage: (page: number) => void;
  userData: IUserData;
  setUserData: (userData: IUserData) => void;
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Readonly<Props>) {
  const {
    window,
    numberOfDocs,
    typeOfDocs,
    setTypeOfDocs,
    setPage,
    userData,
    setUserData,
  } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  let appTypeLabel = typeOfDocs;
  if (!appTypeLabel) appTypeLabel = "Docs";

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (item: string) => {
    setTypeOfDocs(item);
    setPage(1);
    navigate("/");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
        <img
          src={logo}
          alt="logo"
          style={{
            height: "auto",
            width: "100%",
            marginBottom: "-12px",
          }}
        />
      </Link>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => handleClick("docs")}
          >
            <ListItemText primary={"Docs"} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => handleClick("talks")}
          >
            <ListItemText primary={"Talks"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", justifyContent: "center" }}
          >
            <Link
              to="/top20"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText primary={"Top 20"} />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            sx={{ textAlign: "center", justifyContent: "center" }}
          >
            <Link
              to="/favourites"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItemText primary={"Favs"} />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            sx={{ textAlign: "center", justifyContent: "center" }}
          >
            <Link
              to="/subscribe"
              style={{ textDecoration: "none", color: "black" }}
            >
              {userData?.isLogged ? (
                <ProfileCircle
                  email={userData?.email}
                  setUserData={setUserData}
                  userData={userData}
                />
              ) : (
                <ListItemText primary={"Login"} />
              )}
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        overflowX: "hidden", // Prevent horizontal scroll
        width: "100vw", // Ensure the box doesn't exceed the viewport width
      }}
    >
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {mobileOpen && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              4Seven Docs
            </Typography>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "4px",
                marginBottom: "-4px",
              }}
            >
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                <img src={logo} alt="logo" style={{ height: "44px" }} />
              </Link>
              {/* <Typography
                sx={{
                  marginTop: "-4.5px",
                  marginLeft: "48px",
                  color: "lightGrey",
                }}
              >{`${capitalizeFirstChar(
                appTypeLabel
              )} found: ${numberOfDocs}`}</Typography>*/}
            </div>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <AppBarSelect
              typeOfDocs={typeOfDocs}
              setTypeOfDocs={setTypeOfDocs}
              setPage={setPage}
            />
            <Link
              to="/top20"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button
                sx={{
                  color: "#fff",
                }}
              >
                Top 20
              </Button>
            </Link>
            <Link
              to="/favourites"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button sx={{ color: "#fff" }}>Favs</Button>
            </Link>

            {userData?.isLogged ? (
              <Button sx={{ color: "#fff" }}>
                <ProfileCircle
                  email={userData?.email}
                  setUserData={setUserData}
                  userData={userData}
                />
              </Button>
            ) : (
              <Link
                to="/subscribe"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button sx={{ color: "#fff" }}>Login</Button>
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{ p: 3, overflowX: "hidden", width: "100%" }} // Ensure no horizontal overflow in the main content area
      >
        <Toolbar />
      </Box>
    </Box>
  );
}
